<template>
    <div>
        <v-container fluid>

            <Overlay :overlay="overlay"/>

            <v-row>
                <v-col class="text-end">
                    <v-btn color="secondary" depressed @click="$refs.databaseForm.open()">
                        <v-icon left>mdi-plus</v-icon>
                        Base de données
                    </v-btn>
                </v-col>
            </v-row>

            <v-row v-if="isLoading">
                <v-col v-for="item in 8" :key="item" cols="3">
                    <v-card class="shadow rounded-lg">
                        <v-card-text>
                            <v-skeleton-loader type="avatar,article,actions"/>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row v-else>

                <v-col class="d-flex flex-column" cols="3">
                    <v-card class="shadow rounded-lg flex d-flex flex-column">

                        <v-card-title>
                            <v-spacer/>
                            <span class="fs-14">
                <v-icon color="success" dense>mdi-email-check</v-icon>
                {{ allApproved() }}
              </span>
                        </v-card-title>

                        <div class="text-center pa-2">

                            <v-progress-circular :value="allApprovedPercent()"
                                                 class="grey lighten-5 rounded-circle"
                                                 color="red"
                                                 rotate="50"
                                                 size="75"
                                                 width="3"
                            >
                                <v-avatar class="" size="80">
                                    <database-icon :style="{width:'40px'}"/>
                                </v-avatar>
                            </v-progress-circular>

                        </div>
                        <v-card-text class="text-center pa-0 flex">

                            <p class="font-weight-medium px-5 pt-2 grey--text text--darken-2">
                                BDD Globale
                            </p>

                            <v-chip class="bg-primary-subtle font-weight-medium"
                                    small
                                    to="/database/global">
                                <v-icon color="red" left small>mdi-nature-people</v-icon>
                                {{ allPos() }} Propriétaires
                                <v-icon color="red" right small>mdi-arrow-right</v-icon>
                            </v-chip>

                        </v-card-text>
                        <v-divider class="mt-4"/>
                        <v-card-actions>

                            <v-spacer></v-spacer>

                            <v-tooltip bottom color="primary">
                                <template v-slot:activator="{ on }">
                                    <v-btn color="primary"
                                           depressed
                                           fab
                                           x-small
                                           @click="$func.export('database/export-global-excel')"
                                           v-on="on">
                                        <v-icon>mdi-download-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Exporter</span>
                            </v-tooltip>

                            <v-tooltip bottom color="primary">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="ml-1"
                                           color="primary"
                                           depressed
                                           fab
                                           to="/database/global"
                                           x-small
                                           v-on="on">
                                        <v-icon dense>mdi-eye-plus-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Propriétaires</span>
                            </v-tooltip>
                        </v-card-actions>
                    </v-card>
                </v-col>

                <v-col v-for="(item,index) in databases" :key="index" class="d-flex flex-column" cols="3">
                    <v-card class="shadow rounded-lg flex d-flex flex-column">

                        <v-card-title>
                            <v-spacer/>
                            <span class="fs-14">
                <v-icon color="success" dense>mdi-email-check</v-icon>
                {{ item.approved_count }}
              </span>
                        </v-card-title>

                        <div class="text-center pa-2">

                            <v-progress-circular :value="item.approved_percent"
                                                 class="grey lighten-5 rounded-circle"
                                                 color="primary"
                                                 rotate="50"
                                                 size="75"
                                                 width="3"
                            >
                                <v-avatar class="" size="80">
                                    <database-icon :style="{width:'40px'}"/>
                                </v-avatar>
                            </v-progress-circular>

                        </div>
                        <v-card-text class="text-center pa-0 flex">

                            <p class="font-weight-medium px-5 pt-2 grey--text text--darken-2"> {{ item.name }}</p>

                            <v-chip :to="'/database/'+item.id + '/show'"
                                    class="bg-primary-subtle font-weight-medium"
                                    small
                                    text-color="secondary">
                                <v-icon color="secondary" left small>mdi-nature-people</v-icon>
                                {{ item.salepoints_count }} Propriétaires
                                <v-icon color="secondary" right small>mdi-arrow-right</v-icon>
                            </v-chip>

                        </v-card-text>
                        <v-divider class="mt-4"/>
                        <v-card-actions>

                            <v-switch v-model="item.is_active"
                                      class="pa-0 ma-0"
                                      color="success"
                                      hide-details
                                      readonly
                                      @click="changeStatus(item.id,index)"></v-switch>

                            <v-spacer/>

                            <v-tooltip bottom color="primary">
                                <template v-slot:activator="{ on }">
                                    <v-btn color="secondary" icon
                                           @click="$func.export('database/export-excel',{database_id : item.id})"
                                           v-on="on">
                                        <v-icon>mdi-download-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Exporter</span>
                            </v-tooltip>

                            <v-tooltip bottom color="primary">
                                <template v-slot:activator="{ on }">
                                    <v-btn :to="'/database/'+item.id + '/show'" color="secondary" icon v-on="on">
                                        <v-icon>mdi-eye-plus-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Propriétaires</span>
                            </v-tooltip>

                            <v-tooltip bottom color="primary">
                                <template v-slot:activator="{ on }">
                                    <v-btn color="secondary" icon @click="$refs.databaseForm.open(item)" v-on="on">
                                        <v-icon>mdi-pencil-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Modifier</span>
                            </v-tooltip>

                            <v-tooltip bottom color="primary">
                                <template v-slot:activator="{ on }">
                                    <v-btn color="red" icon @click="deleteDatabase(item.id,index)" v-on="on">
                                        <v-icon>mdi-trash-can-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Supprimer</span>
                            </v-tooltip>

                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>

            <div v-if="!isLoading && !databases.length">
                <v-card class="transparent" flat>
                    <v-card-text>
                        <NoResult/>
                    </v-card-text>
                </v-card>
            </div>

        </v-container>

        <ConfirmDialog ref="confirmDialog"/>
        <DatabaseForm ref="databaseForm" @refresh="getDatabase"/>

    </div>
</template>

<script>
import {HTTP} from "@/http-common";
import DatabaseIcon from "@/components/svg-icons/DatabaseIcon.vue";
import DatabaseForm from "@/views/database/components/DatabaseForm.vue";

export default {
    components: {
        DatabaseIcon,
        DatabaseForm,
    },
    data() {
        return {
            overlay: false,
            isLoading: false,
            databases: [],
        }
    },
    methods: {
        getDatabase() {
            this.isLoading = true
            HTTP.get('/database').then((res) => {
                this.isLoading = false
                this.databases = res.data.data
            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
        async deleteDatabase(id, index) {
            if (await this.$refs.confirmDialog.open()) {
                this.overlay = true
                HTTP.delete('database/' + id + '/delete').then(() => {
                    this.databases.splice(index, 1)
                    this.overlay = false
                    this.$successMessage = 'Cet élément a été supprimé avec succès.'
                }).catch(err => {
                    this.overlay = false
                    console.log(err)
                })
            }
        },
        async changeStatus(databaseId, index) {
            this.overlay = true
            HTTP.post('/database/' + databaseId + '/change-status').then(() => {
                this.overlay = false
                this.$successMessage = 'Cette base de données a été modifiée avec succès.'
                this.databases[index]['is_active'] = !this.databases[index]['is_active']
            }).catch(err => {
                this.overlay = false
                console.log(err)
            })
        },
        allPos() {
            return this.databases.reduce(function (sum, data) {
                return sum + parseInt(data.salepoints_count)
            }, 0)
        },
        allApproved() {
            return this.databases.reduce(function (sum, data) {
                return sum + parseInt(data.approved_count)
            }, 0)
        },
        allApprovedPercent() {
            return this.allApproved() * 100 / this.allPos()
        },
    },
    created() {
        this.getDatabase()
    }
}
</script>

<style scoped>

</style>